.App {
  text-align: center;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/birria-el-compa.appspot.com/o/el-compa-banner-web-fondo.jpg?alt=media&token=b82377e1-c968-49d2-b950-c126ae0e2661');
  background-size: cover;
  padding: 0 15px;
}

.logo {
  width: 100%;
}

.logo-container {
  width: 100%;
  max-width: 1200px;
}

.App-header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
